<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="loadRemuneraciones()"
              >
                <v-row>
                  <v-col cols="4" class="py-0">
                    <v-text-field
                      dense
                      outlined
                      v-model="periodoDesde"
                      label="Ingrese un período"
                      hint="Formato AAAAMM"
                      persistent-hint
                      type="text"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      v-mask="'######'"
                      @keyup.enter="loadRemuneraciones()"
                      :rules="rules.periodoYyyyMm"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    elevation="2"
                    small
                    :disabled="!isFormValid"
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>

        <v-data-table
          :headers="headers"
          :items="registroRemuneraciones"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="12" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar remuneración</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteRemuneracion(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar remuneración</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="50%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditTopesEnRemuneraciones
        :editTopeEnRemuneracion="topesEnRemuneracionParams"
        @closeAndReload="closeAndReload"
        :topeRemunId="topeRemunId"
      ></EditTopesEnRemuneraciones>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditTopesEnRemuneraciones from "@/components/modules/cuotas/configuracion/EditTopesEnRemuneraciones.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    EditTopesEnRemuneraciones,
    GoBackBtn,
    FiltersSelected,
    Ayuda
  },
  name: "TopesEnRemuneraciones",
  directives: { mask },
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    searchIcon: enums.icons.SEARCH,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    isFormValid: false,
    search: "",
    isLoading: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.TOPES_REMUNERACIONES,
    showFilters: true,
    filtersApplied: [],
    showDeleteModal: false,
    idToDelete: null,
    periodoDesde: null,
    topesEnRemuneracionParams: {},
    topeRemunId: null,
    registroRemuneraciones: [],
    routeToGo: "ConfiguracionDDJJAportes",
    title: enums.titles.TOPES_EN_REMUNERACIONES,
    titleDelete: enums.titles.DELETE_REMUNERACION,
    rules: rules,
    headers: [
      {
        text: "Tope de remuneración",
        value: "remuneracion",
        sortable: false,
        width: "15%",
        align: "end"
      },
      // está para crear un espacio en blanco entre la remuneración y el período
      {
        text: "",
        value: "",
        sortable: false
      },
      {
        text: "Período desde",
        value: "periodoDesde",
        sortable: false
      },
      {
        text: "Período hasta",
        value: "periodoHasta",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false,
    openModalLog: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    let traerPeriodoActual = this.hoyFecha();
    this.periodoDesde = traerPeriodoActual;
    this.loadRemuneraciones();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },

  methods: {
    ...mapActions({
      fetchTopesRemuneracionesByVigencia:
        "configuracion/fetchTopesRemuneracionesByVigencia",
      deleteTopeRemuneracion: "configuracion/deleteTopeRemuneracion",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVA_REMUNERACION:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_REMUNERACION:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_REMUNERACION:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    hoyFecha() {
      let hoy = new Date();
      let mm = hoy.getMonth() + 1;
      let yyyy = hoy.getFullYear();
      if (mm < 10) {
        mm = "0" + mm;
      }
      return yyyy.toString() + mm.toString();
    },
    // metodos de filtros
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.periodoDesde) {
        this.filtersApplied.splice(0, 1, {
          key: "periodoDesde",
          label: "Período desde",
          model: this.periodoDesde
        });
      }
    },
    async loadRemuneraciones() {
      this.isLoading = true;
      const data = await this.fetchTopesRemuneracionesByVigencia(
        this.periodoDesde
      );
      this.customizeFiltersApplied();
      this.registroRemuneraciones = data;
      this.showFilters = false;
      this.isLoading = false;
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    deleteRemuneracion(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteTopeRemuneracion(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadRemuneraciones();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.topesEnRemuneracionParams = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadRemuneraciones();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
